<template>
  <div id="addressup">
    <top :name="text" />
    <van-address-edit
      :area-list="areaList"
      :show-postal="is_true"
      :show-delete="isdelete"
      :address-info="list_arr"
      show-search-result
      :search-result="searchResult"
      :area-columns-placeholder="['请选择', '请选择', '请选择']"
      @save="onSave"
      @delete="onDelete"
      @change-detail="onChangeDetail"
    />
  </div>
</template>

<script lang="ts">
import top from "../components/filltop.vue";
import { areaList } from "@vant/area-data";
export default {
  data() {
    return {
      text: "",
      isdelete: false,
      list_arr: {},
      areaList: areaList,
      searchResult: [], //定位地址
      is_true : false
    };
  },
  components: {
    top,
  },
  methods: {
    onSave(content) {
      // 新增地址
      console.log(content);
      
      if (this.text == "新增地址") {
        this.$https(
          "malldock/address",
          "id=" +
            sessionStorage.getItem("user_id") +
            "&phone=" +
            sessionStorage.getItem("user_phone") +
            "&tel=" +
            content.tel +
            "&city=" +
            content.city +
            "&county=" +
            content.county +
            "&province=" +
            content.province +
            "&addressDetail=" +
            content.addressDetail +
            "&areaCode=" +
            content.areaCode +
            "&name=" +
            content.name,
          "post"
        ).then((res) => {
          // console.log(res)
          if (res.data.code == "000000") {
            this.$toast.success(res.data.msg);
            setTimeout(() => {
              this.$router.go(-1);
            }, 1000);
          } else {
            this.$toast.fail(res.data.msg);
          }
        });
      } else {
        this.$https(
          "malldock/updateaddress",
          "id=" +
            sessionStorage.getItem("user_id") +
            "&phone=" +
            sessionStorage.getItem("user_phone") +
            "&tel=" +
            content.tel +
            "&city=" +
            content.city +
            "&renid=" +
            content.id +
            "&county=" +
            content.county +
            "&province=" +
            content.province +
            "&addressDetail=" +
            content.addressDetail +
            "&areaCode=" +
            content.areaCode +
            "&name=" +
            content.name,
          "post"
        ).then((res) => {
          // console.log(res)
          if (res.data.code == "000000") {
            this.$toast.success(res.data.msg);
            setTimeout(() => {
              this.$router.go(-1);
            }, 1000);
          } else {
            this.$toast.fail(res.data.msg);
          }
        });
      }
    },
    // 删除地址
    onDelete() {
      console.log(this.list_arr)
      this.$https(
        "malldock/deleteaddress",
        "phone="+sessionStorage.getItem("user_phone")+
        "&id="+sessionStorage.getItem("user_id")+
        "&renid="+this.list_arr.id,
        "post"
      ).then(res=>{
        if(res.data.code == '000000'){
          this.$toast.fail(res.data.msg);
          setTimeout(()=>{
            this.$router.go(-1);
          },1000)
        }else{
          this.$toast.fail(res.data.msg);
        }
      })
    },
    onChangeDetail(val) {
      // if (val) {
      //   this.searchResult = [
      //     {
      //       name: "黄龙万科中心",
      //       address: "杭州市西湖区",
      //     },
      //   ];
      // } else {
      //   this.searchResult = [];
      // }
    },
  },
  mounted() {
    this.text = this.$route.params.id;
    if (this.text == "编辑地址") {
      this.isdelete = true;
      // 延迟0..1秒等待下个钩子函数回显数据
        this.list_arr = {
          id: JSON.parse(this.$route.params.data).id,
          name: JSON.parse(this.$route.params.data).name,
          tel: JSON.parse(this.$route.params.data).tel,
          province: JSON.parse(this.$route.params.data).province,
          city: JSON.parse(this.$route.params.data).city,
          county: JSON.parse(this.$route.params.data).county,
          addressDetail: JSON.parse(this.$route.params.data).mergename,
          areaCode: JSON.parse(this.$route.params.data).areaCode,
        };
    }

    // console.log(this.$route.params)
    // 编辑地址

    // this.$set(this.list_arr,{
    //   id : 1,
    //   name : '袁',
    //   tel : "17347367179",
    //   province : "湖南省",
    //   city : "常德市",
    //   county : '汉寿县',
    //   addressDetail : "碧桂园2期",
    //   areaCode : "420882"
    // })
    // id	每条地址的唯一标识	number | string
    // name	收货人姓名	string
    // tel	收货人手机号	string
    // province	省份	string
    // city	城市	string
    // county	区县	string
    // addressDetail	详细地址	string
    // areaCode	地区编码，通过省市区选择获取（必填）	string
    // postalCode	邮政编码	string
    // isDefault	是否为默认地址
    console.log(this.list_arr);
  },
};
</script>

<style scoped>
#addressup {
  height: 100%;
  background: #f8f8f8;
}
</style>